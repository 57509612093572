@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/elements/container";
@import "bulma/sass/form/_all";
@import "bulma/sass/components/tabs";

@import "../../assets/bulma-overrides/bulmaOverrides";

/* Main container for AI Calculator Generator */
.ai-calculator-generator-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  font-family: $bricolage-font !important;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 1500px;
  height: auto;
  min-height: 100vh;
  margin: 0px auto;
  overflow: visible;
  width: 100%;

  /* Responsive adjustments */
  @media (max-width: 480px) {
    height: auto;
    width: 100%;
  }

  @media (max-width: 769px) {
    height: auto;
    width: 100%;
  }

  @media (min-width: 1024px) {
    width: 100vw;
    max-width: 100%;
  }

  /* Header section with back button */
  .ai-calculator-generator-header {
    position: relative;
    width: 100%;
    height: 50px;

    .ai-calculator-back-button {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      transition: background-color 0.2s;
      position: absolute;
      left: 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  /* Content section */
  .ai-calculator-generator-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    /* Title and description */
    .ai-calculator-generator-title {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 4px;
      text-align: center;
      font-family: $bricolage-font;
    }

    .ai-calculator-generator-description {
      font-size: 1rem;
      color: #666;
      text-align: center;
      max-width: 800px;
      // margin-bottom: 40px;
    }

    /* Input card for calculator type */
    .ai-calculator-input-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;

      .ai-calculator-input-group {
        width: 100%;
        margin-bottom: -7px;

        label {
          display: block;
          display: flex;
          align-items: center;
          gap: 12px;
          font-weight: 600;
          margin-bottom: 12px;
          // font-size: 1.1rem;
          color: #333333;
          white-space: nowrap;
        }

        .ai-calculator-type-input {
          // width: 100%;
          padding: 6px 16px;
          font-size: 1rem;
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          background-color: #fff;
          transition: all 0.2s ease;

          &:focus {
            outline: none;
            border-color: $primary;
            box-shadow: 0 0 0 3px rgba($primary, 0.1);
          }

          &::placeholder {
            color: #999;
          }
        }
      }

      .ai-generate-calculator-button {
        background-color: $primary;
        color: white;
        border: none;
        border-radius: 8px;
        padding: 13px 63px;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background-color: darken($primary, 5%);
          transform: translateY(-1px);
          box-shadow: 0 4px 12px rgba($primary, 0.2);
        }

        &:active {
          transform: translateY(0);
        }

        &:disabled {
          background-color: lighten($primary, 20%);
          cursor: not-allowed;
          transform: none;
          box-shadow: none;
        }
      }

      .ai-calculator-text{
        font-size: 1rem;
        font-weight: 600;
        margin-left: -40px;
        color: #333333;

      }
      .textarea-box{
        text-align: start;
        height: 170px;
        min-height: 86px;
        margin-bottom: 16px;
        margin-top: 10px;
        /* display: block; */
        resize: vertical;
        width: 100%!important;
        border-radius: 10px;
        padding: 17px;
      }
    }

    /* Results section with chat and preview */
    .ai-calculator-result-section {
      gap: 18px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: 1046px) {
        flex-direction: column;
      }

      .ai-horizontal-line{
        background-color: rgba(0, 0, 0, 0.1);
        width: 1.6px;
        height: 86vh;
        margin-top: 0;

        @media (max-width: 1046px) {
          height: 2px;
          width: 100%;
        }
      }

      /* Chat container */
      .ai-calculator-chat-container {
        margin-left:5px;
        display: flex;

        .ai-content {
          display: flex;
          flex-direction: column;
          gap: 15px;
          flex-grow: 1;

          @media (max-width: 1046px) {
            width: 100%;
          }

          .title-text {
            font-size: 1.6rem;
            font-weight: 500;
            margin-left: 7px;
          }

          .custom-textarea {
            border-radius: 7px;
            height: 170px;
            min-height: 86px;
            resize: vertical;
          }

          .custom-button{
            width: fit-content;
          }

          input[type="checkbox"] {
            accent-color: #000000;
          }
          
        }
      }

      /* Embed container */
      .ai-embed-container {
        .ai-section{
          display: flex;
          flex-direction: column;
          gap: 12px;

          .textarea{
            border-radius: 10px;
          }

          .subtitle{
            font-size: 1.2rem;
            margin-left: 7px;
            font-weight: 600;
          }


          .copy-btn{
            width: 19%;
            height: 36px;
            font-size: 18px;
            border-radius: 8px;
            margin-left: auto;
          }

        }
      }

      /* version container */
      .ai-version-container {
        margin-left: 12px;

        .custom-radio {
          margin-bottom: 0.8rem;
          appearance: none;
          width: 16px;
          height: 16px;
          border: 2px solid black;
          border-radius: 50%;
          position: relative;
          cursor: pointer;
          outline: none;  
        }

        .custom-radio:checked::before {
          content: "";
          width: 8px;
          height: 8px;
          background-color: black;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      /* Preview section */
      .ai-calculator-preview-section {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        // overflow: hidden;
        border: 1px solid #80808080;
        flex: 0 0 42%;
        margin: 0 auto;
        margin-top: 2.5rem;

          @media (max-width: 1046px) {
            width: 100%;
            margin-top: 0;
          }
        

        .ai-preview-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px;
          border-bottom: 1px solid #eee;

          h3 {
            font-family: $bricolage-font;
            font-size: 1.25rem;
            margin: 0;
          }

          .ai-preview-actions {
            display: flex;
            gap: 8px;

            .ai-action-button {
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              background-color: #f0f0f0;
              color: $grey-darker;
              cursor: pointer;
              transition: background-color 0.2s;

              &.ai-text-button {
                padding: 8px 16px;
                border-radius: 4px;
                font-size: 0.9rem;
              }

              &:hover {
                background-color: darken(#f0f0f0, 5%);
              }
            }
          }
        }

        .ai-preview-container {
          padding: 16px;
          height: 500px;
          overflow: auto;

          .ai-preview-content {
            width: 100%;
            height: 100%;
          }
        }
      }
      .ai-left-container{
        overflow-y: auto;
        margin-top: 1.81rem;
        flex:  0 0 48%;

        @media (max-width: 1046px) {
          width: 100%;
        }
        
        .tabs{
          scrollbar-width: none;
        
          ul{
            border-bottom: none !important;
            gap: 40px;
            justify-content: space-between;

            @media (min-width: 1570px) {
              justify-content: center;
            }

            li a{ 
              border-bottom: none !important;
              font-weight: 400;
              font-size: 1.2rem;
              padding: 0.3em 0em;
            }

            li.is-active a{
              border-bottom: none !important;
              background: #D9EFFE;
              font-weight: 600;
              font-size: 1.2rem;
              border-radius: 8px;
              padding: 0.3em 0.6em;
            }
          }
        }
      }
    }
  }
  .horizontal-line{
    width: 100%;
    height: 1px;
    margin-bottom: 0;
    background-color: #dedbdb;
  }
}
