@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";

@import "bulma/sass/utilities/all";
@import "bulma/sass/base/all";
@import "bulma/sass/helpers/typography";
@import "bulma/sass/grid/columns";
@import "bulma/sass/elements/container";
@import 'bulma/sass/form/_all';
@import "bulma/sass/components/tabs";

@import "../../assets/bulma-overrides/bulmaOverrides";

@media (max-width: 450px) {
    .blog-finder-responsive-content {
        display: none; 
    }

    .blog-finder-upload-container {
        padding: 10px;
    }

    .blog-finder-upload-box {
        max-width: 240px; 

        .upload-input{
            width: 188px;
        }

    }
}

.blog-finder-input-container{
    flex-grow: 1;
    margin: 0 auto;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-container {
    padding: 20px 0 0 0;
}

.blog-form-group{
    align-items: flex-start !important;
    width: 90%;
    margin: auto;
}

.blog-form-container{
    margin-left: 0;
    padding: 0;
}

.blog-container h1 {
    font-family: $bricolage-font !important;
    font-size: 2rem !important;
    font-weight: 400;
}

.blog-form{
    width: 95%;
    margin: auto !important;
}

.blog-form-container .abun-table-responsive .abun-table-filter-buttons{
    width: 520px;

    @media (max-width: 768px) {
        .btns-container{
          margin-top:0rem !important;
        }
      }
}