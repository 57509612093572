/* Container */
.changelog-container {
  width: 100%;
  margin: 0 auto;

  /* Title */
  .changelog-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 16px;
  }
  /* Changelog Card */
  .changelog-card {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
    transition: all 0.3s ease-in-out;
    width: 100%;
    margin-bottom: 10px;

    /* Header: Title & "New" Badge */
    .changelog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      /* Title */
      .changelog-title-text {
        font-size: 1.125rem;
        font-weight: 600;
      }
    }

    /* Description */
    .changelog-description {
      color: #4b5563;
      margin-top: 8px;
      font-family: inter;
    }

    /* Footer (Date and Button Container) */
    .changelog-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;

      /* Read More Button */
      .changelog-read-more {
        color: #fff;
        background-color: #3f77f8;
        padding: 6px 12px;
        border-radius: 6px;
        font-size: 0.875rem;
        cursor: pointer;
        border: none;
        transition: background-color 0.2s ease-in-out;
      }

      .changelog-read-more:hover {
        background-color: #336ef8;
      }

      /* Date */
      .changelog-date {
        color: #6b7280;
        font-size: 0.875rem;
      }
    }
  }

  .changelog-card:hover {
    transform: translateY(-2px);
  }

  /* No Updates Message */
  .no-updates {
    text-align: center;
    color: #6b7280;
    font-size: 1rem;
    margin-top: 16px;
  }
}
