.integration-card {
  align-items: center;
  padding: 20px 30px;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 0px auto;
  width: 100vw;

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    white-space: normal;
  }

  .subtitle {
    font-size: 16px;
    color: #555;
    margin-bottom: 40px;
    text-align: center;
  }

  .tabs {
    display: flex;
    width: 100% !important;
    max-width: 1000px;
    gap: 8px;
    justify-content: space-between !important;
    border-bottom: 1px solid #ccc;
    margin: 0 auto;

    @media (max-width: 769px) {
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  .tabs::-webkit-scrollbar {
    display: none;
  }

  .tab {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    border: none;
    background: none;
    color: #555;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    transition: color 0.3s ease;
  }

  .tab.active {
    color: #007bff;
    border-bottom: 2px solid #007bff;
  }

  .tab img.integration-item-logo {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  .tab.active::after {
    content: none;
  }

  .site-list {
    margin-top: 10px;

    @media (max-width: 1200px) {
      overflow-x: auto;
      white-space: nowrap;
    }

    .header {
      font-size: 18px;
    }

    .header-action-wordpress {
      font-size: 18px;
      justify-self: center;

      @media (max-width: 480px) {
        margin-left: 200px;
      }

      @media ((min-width: 480px) and (max-width: 769px)) {
        margin-left: 80px;
      }
    }

    .header-row {
      display: grid;
      justify-content: space-between;
      font-weight: bold;
      padding: 10px;
      border-bottom: 1px solid #ccc;
      grid-template-columns: repeat(2, 1fr);
      color: #3f77f8;

      @media (max-width: 1024px) {
        display: flex;
      }
    }

    .site-row {
      display: grid;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid #f1f1f1;
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: 1024px) {
        display: flex;
      }

      .delete-btn {
        margin-right: 10px;
      }
    }

    .header-row-webflow {
      display: grid;
      justify-content: space-between;
      font-weight: bold;
      padding: 10px;
      border-bottom: 1px solid #ccc;
      grid-template-columns: repeat(4, 1fr);
      color: #3f77f8;

      @media (max-width: 1024px) {
        gap: 230px;
      }
      @media (max-width: 1024px) and (min-width: 768px) {
        .header-action-webflow {
          margin-left: -80px;
        }
      }
    }

    .site-row-webflow {
      display: grid;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid #f1f1f1;
      grid-template-columns: repeat(4, 1fr);

      @media (max-width: 1024px) {
        gap: 60px;
      }

      @media (max-width: 769px) {
        .delete-btn {
          margin-left: 40px;
        }
      }

      @media (max-width: 1024px) and (min-width: 768px) {
        .delete-btn {
          margin-left: 70px;
        }
      }
    }

    .header-action-webflow {
      font-size: 18px;
      // margin-left: 150px;
      justify-self: center;

      @media (max-width: 769px) {
        margin-left: -150px;
      }
    }

    .header-row-wix {
      display: grid;
      justify-content: space-between;
      font-weight: bold;
      padding: 10px;
      border-bottom: 1px solid #ccc;
      grid-template-columns: repeat(3, 1fr);
      color: #3f77f8;
      white-space: nowrap;

      @media (max-width: 1062px) {
        gap: 400px;
      }
    }

    .site-row-wix {
      display: grid;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid #f1f1f1;
      grid-template-columns: repeat(3, 1fr);

      @media (max-width: 1062px) {
        gap: 340px;
      }
    }

    .header-action-wix {
      font-size: 18px;

      @media (min-width: 1062px) {
        justify-self: center;
      }
    }

    .site-url {
      font-size: 14px;
      color: #333;
      white-space: nowrap;
    }

    .delete-btn {
      background: none;
      border: none;
      font-size: 16px;
      color: red;
      cursor: pointer;
    }

    .delete-btn-wix {
      background: none;
      border: none;
      font-size: 16px;
      color: red;
      cursor: pointer;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    }

    .total {
      font-size: 16px;
      color: #333;
      gap: 5px;

      @media (max-width: 500px) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .no-sites-connected {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #555;
      margin-top: 10px;
    }

    .pagination {
      display: flex;
      align-items: center;
      gap: 8px;

      @media (max-width: 500px) {
        margin-top: 32px;
      }
    }

    .per-page {
      padding: 4px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-left: 5px;
    }

    .page-numbers {
      font-size: 16px;
      color: #555;
    }

    .connect-btn-integration {
      padding: 10px 20px;
      background: #3f77f8;
      color: #fff;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      font-family: "Bricolage Grotesque";

      @media (min-width: 1024px) {
        margin-right: 180px;
      }

      @media (max-width: 768px) {
        padding: 12px 32px;
      }
    
    }

    .connect-btn {
      padding: 10px 20px;
      background: #3f77f8;
      color: #fff;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      margin-bottom: 20px;
      gap: 8px;
      font-family: "Bricolage Grotesque";

      @media (min-width: 1430px) {
        margin-right: 180px;
      }

      @media (max-width: 525px) {
        margin-top: 20px;
        margin-left: 0;
      }
    }
    .connect-btn:focus{
      border-color: #2684FF;
      outline:none !important;
      transition: all 100ms;
    }
  }

  .wp-failed-to-fetch-rest-route-error-box {
    background-color: #fff4f4;
    border: 1px solid #ffcdd2;
    border-radius: 8px;
    padding: 16px;
    margin-top: 1rem;

    h4 {
      color: #e53935;
      font-weight: bold;
      margin-bottom: 10px;
    }

    h5 {
      font-weight: bold;
      margin-bottom: 8px;
    }

    p {
      margin-top: 12px;
    }

    ul {
      padding-left: 20px;
      list-style: none;

      li {
        margin-bottom: 6px;
      }
    }
  }

  .integration-input{
    input{
      border-radius: 10px;
    }
  }
}
