@import "../../assets/themes/mainTheme";
@import "../../assets/fonts/customFonts";
@import "bulma/sass/utilities/all";
@import "bulma/sass/elements/table";
@import "bulma/sass/base/all";

.gsc-container {
    background-color: #f8f9fb;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    min-height: 50vh;

    h2 {
        font-size: 24px;
        color: #333;
        font-weight: 600;
        font-family: "Bricolage Grotesque", normal !important;
    }

    p {
        font-size: 14px;
        color: #666;
        font-family: $inter-font !important;
    }

    .performance-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            font-size: 20px;
            font-weight: 600;
            color: #333;
            margin: 0;
            font-family: "Bricolage Grotesque", normal !important;
        }
    }

    .export-btn {
        display: flex;
        align-items: center;
        gap: 8px;
        background: white;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 8px 12px;
        font-size: 16px;
        font-weight: 500;
        color: #333;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        margin-right: 20px;

        &:hover {
            background: #f3f3f3;
        }

        .icon {
            color: #333;
        }
    }

    .filters {
        font-size: 14px;
        color: #374151;
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .divider {
        color: #9ca3af;

        @media(max-width:767px) {
            display: none;
        }
    }

    .filter-dropdown {
        margin-left: 5px;
        border: none;
        font-size: 14px;
        background: transparent;
        cursor: pointer;
        font-family: $inter-font !important;
    }


    /* Statistics Cards */
    .gsc-stats {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 20px;
        align-items: flex-start;
        gap: 10px;

        .stat-card {
            flex: 1;
            min-width: 250px;
            margin: 0 10px;
            padding: 15px;
            border-radius: 8px;
            color: #333;
            background: white;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
        }

        .stat-card h3 {
            font-size: 24px;
            margin: 5px 0;
            font-weight: 700;
            font-family: "Bricolage Grotesque", normal !important;
        }

        .stat-card p {
            font-size: 18px;
            margin: 0;
            font-family: $inter-font !important;
        }

        .stat-card span {
            font-size: 14px;
            font-family: $inter-font !important;
        }

        /* Colors */
        .stat-card.blue {
            background-color: rgb(239, 246, 255);
        }

        .stat-card.blue h3 {
            color: rgb(37, 99, 235);
            /* Darker blue */
        }

        .stat-card.purple {
            background-color: rgb(250, 245, 255);
        }

        .stat-card.purple h3 {
            color: rgb(168, 85, 247);
            /* Darker purple */
        }

        .stat-card.green {
            background-color: rgb(240, 253, 244);
        }

        .stat-card.green h3 {
            color: rgb(16, 185, 129);
            /* Darker green */
        }

        .stat-card.orange {
            background-color: rgb(255, 247, 237);
        }

        .stat-card.orange h3 {
            color: rgb(245, 158, 11);
            /* Darker orange */
        }

    }

    /* Charts Section */
    .gsc-charts {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 20px;
        gap: 10px;
    }

    .chart-card {
        flex: 1;
        min-width: 250px;
        background: white;
        padding: 15px;
        border-radius: 8px;
        margin: 0 10px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

        h4 {
            font-size: 20px;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
            font-weight: 500;
        }
    }

    .gsc-table {
        margin-top: 20px;
        padding: 15px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        @media (max-width: 480px) {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }

        .table-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            background: #f8f9fa;
            border-radius: 6px;
            margin-bottom: 10px;

            @media(max-width:767px) {
                flex-direction: column;
            }

            .left-controls {
                display: flex;
                align-items: center;
                gap: 10px;

                button {
                    background: none;
                    border: 1px solid #ddd;
                    padding: 8px 12px;
                    cursor: pointer;
                    font-weight: 500;
                    color: #555;
                    border-radius: 5px;

                    &.active {
                        background: #007bff;
                        color: #fff;
                        border: none;
                    }
                }

                .entries {
                    color: #666;
                    font-size: 14px;
                }
            }

            .right-controls {
                display: flex;
                align-items: center;
                gap: 10px;

                @media(max-width:767px) {
                    margin-top: 10px;
                }

                .search-wrapper {
                    position: relative;
                    display: flex;
                    align-items: center;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    background: white;
                    padding: 4px;
                    width: 280px; // Adjust width as needed

                    input {
                        flex: 1;
                        padding: 8px 12px 8px 35px; // More padding for the icon
                        border: none;
                        outline: none;
                        font-size: 14px;
                        color: #333;
                        background: transparent;
                    }

                    .search-icon {
                        position: absolute;
                        left: 10px;
                        color: #999;
                    }

                    .copy-btn {
                        background: white;
                        border: 1px solid #ddd;
                        padding: 6px 10px;
                        cursor: pointer;
                        font-size: 12px;
                        border-radius: 6px;
                        font-weight: 500;
                        margin-left: auto;
                        transition: background 0.2s ease;

                        &:hover {
                            background: #f3f3f3;
                        }
                    }
                }
            }
        }

        table {
            width: 100%;
            border-collapse: collapse;

            th,
            td {
                padding: 10px;
                text-align: left;
                border-bottom: 1px solid #ddd;
                font-family: $inter-font !important;
            }

            th {
                background: #f8f8f8;
                font-weight: bold;

                .sort-icon-container {
                    margin-right: 5px;
                    cursor: pointer;

                    .active {
                        opacity: 1;
                    }

                    span {
                        padding: 0;
                        margin: 0;
                        font-size: 12px;

                        &.upward-arrow {
                            margin-bottom: -8px;
                        }

                        &.downward-arrow {
                            margin-top: -8px;
                        }
                    }

                    &.descending .upward-arrow {
                        opacity: 0.5;
                    }

                    &.ascending .downward-arrow {
                        opacity: 0.5;
                    }

                    &.not-sorted span {
                        opacity: 0.5;
                    }

                }
            }

            .impression-container {
                @media(min-width:1024px) {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }

                .progress {
                    display: flex;
                    align-items: center;
                    background: #e0e0e0;
                    border-radius: 5px;
                    height: 6px;
                    width: 100px;
                    margin-right: 10px;

                    .bar {
                        height: 100%;
                        background: #007bff;
                        border-radius: 5px;
                    }
                }
            }
        }

        .abun-table-pagination-controls-container {
            width: 100%;
            overflow: auto;
            margin-top: 10px;

            .abun-table-pagination-stats {
                float: left;
                color: black;
                font-family: $secondary-font;
                font-weight: 400;
                font-size: 0.9rem;
            }

            .abun-table-pagination-size-select {
                font-family: $inter-font;
                float: left;

                select {
                    height: auto;
                    padding-top: 0;
                    padding-bottom: 0;
                    font-size: 1rem;
                    padding-right: 2em;
                }
            }

            .abun-table-pagination-page-control {
                display: flex;
                align-items: center;
                justify-content: space-around;
                float: right;
                width: 6.25rem;

                svg {
                    cursor: pointer;
                }

                .page-input-container {
                    display: inline-block;
                    font-family: $inter-font;

                    .page-input {
                        display: inline-block;
                        width: 1.75em;
                        height: min-content;
                        width: 1.75rem;
                        height: 1.5rem;
                        padding: 0.8px 5.8px 1.2px 5.8px;
                        gap: 0px;
                        border-radius: 4px;
                        border: 1px solid #DBDBDB
                    }
                }

                .page-icon {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 2em;
                    height: 2em;
                    border-radius: 100%;
                    border: 2px solid $primary;
                    background-color: $white;
                    color: $primary;
                    font-family: $secondary-font;
                    font-size: 0.9rem;
                    font-weight: 700;
                    text-align: center;
                    margin: 0 5px 0 5px;
                    cursor: pointer;

                    &:hover {
                        background-color: $primary;
                        color: $white;
                    }

                    &.page-active {
                        background-color: $primary;
                        color: $white;
                    }
                }
            }
        }
    }

    .gsc-box {
        background: #ffffff;
        border-radius: 12px;
        border: 1px solid #e5e7eb;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        padding: 24px;
        max-width: 800px;
        margin: 20px auto;

        h2 {
            font-size: 22px;
            font-weight: 500;
            color: #333;
        }

        p {
            font-size: 18px;
            color: #6b7280;
            margin-bottom: 12px;
        }

        .gsc-benefits {
            background: #eff6ff;
            border-radius: 8px;
            padding: 16px;
            font-size: 16px;
            color: #2563eb;
            border: 1px solid #dbeafe;
            margin: 16px 0;
            font-weight: 500;

            .benefits-title {
                font-weight: 500;
                margin-bottom: 8px;
            }

            ul {
                list-style-type: disc;
                padding-left: 20px;
                margin: 0;

                li {
                    margin-bottom: 4px;
                }
            }
        }

        .gsc-privacy {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 12px;
            background: #f9fafb;
            border-radius: 8px;
            margin-top: 16px;

            .privacy-icon {
                font-size: 30px;
            }

            .privacy-text {
                display: flex;
                flex-direction: column;

                .privacy-title {
                    font-size: 18px;
                    font-weight: 500;
                    color: #333;
                }

                p {
                    font-size: 14px;
                    color: #6b7280;
                    margin: 0;
                }
            }
        }

        .gsc-connect-button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            background: #2563eb;
            color: #ffffff;
            font-size: 16px;
            font-weight: 600;
            border: none;
            padding: 10px 16px;
            border-radius: 6px;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out;
            margin-top: 16px;
            height: 36px; // Equivalent to h-9

            &:hover {
                background: #1d4ed8;
            }

            &:disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }

    /* Responsive Layout */
    @media (max-width: 768px) {

        .gsc-stats,
        .gsc-charts {
            justify-content: center;
        }

        .stat-card,
        .chart-card {
            width: 48%;
            margin: 10px 1%;
        }
    }

    @media (max-width: 480px) {

        .stat-card,
        .chart-card {
            width: 100%;
            margin: 10px 0;
        }
    }
}